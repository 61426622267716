import { atom, selector, UnwrapRecoilValue } from 'recoil'
import { callApi } from 'src/utils/api'
import { RateValueEnum, Content, TopRatedJoke } from 'src/types'
import type { ResponseError } from 'src/types'

export const userState = atom({
  key: 'userState',
  default: { isLoggedIn: false, userId: '', token: '' },
})

export const rateAtom = atom({
  key: 'rateAtom',
  default: {
    allRated: false,
    canRate: true,
    currentId: undefined! as number,
    data: undefined! as Content[],
    index: 0,
    rateValue: undefined! as RateValueEnum,
    rated: [] as number[],
  },
})

export const statsAtom = atom({
  key: 'statsAtom',
  default: {
    usersCount: 0,
    jokesCount: 0,
    allRatedCount: 0,
    ownRatedCount: 0,
    topRated: undefined! as TopRatedJoke[],
  },
})

export const rateState = selector<UnwrapRecoilValue<typeof rateAtom>>({
  key: 'rateState',
  get: async ({ get }) => {
    const state = get(rateAtom)
    // const currentId = !state.data ? data[0].contentId : state.currentId
    // const ret = {
    //   ...state,
    //   currentId,
    //   data,
    // }
    // return ret

    try {
      const { token } = get(userState)
      // @ts-ignore
      const { data } = !state.data ? await callApi(token, 'getContent') : state
      const currentId = !state.data ? data[0].contentId : state.currentId
      return {
        ...state,
        initialized: true,
        currentId,
        data,
      }
    } catch (e) {
      const err = e as ResponseError
      if (err.response.status === 404) {
        return {
          ...state,
          data: [],
          canRate: false,
          allRated: true,
        }
      }
      throw e
    }
  },
  set: ({ set }, newValue) => {
    set(rateAtom, newValue)
  },
})

export const statsState = selector<UnwrapRecoilValue<typeof statsAtom>>({
  key: 'statsState',
  get: async ({ get }) => {
    const state = get(statsAtom)
    const { token } = get(userState)

    const response = await callApi(token, 'getStatistics')
    return {
      ...state,
      ...response,
    }
  },
})

export const apiToken = selector({
  key: 'apiToken',
  get: ({ get }) => {
    return get(userState).token
  },
})
