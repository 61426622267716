import React, { useMemo } from 'react'
import { useTable } from 'react-table'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import { statsState } from 'src/state/index'
import { device } from 'src/styles/variables'

const Styles = styled.div`
  table {
    width: 95vw;
    margin-bottom: 10px;
    border-spacing: 0;
    border-radius: 8px;
    background-color: white;

    @media ${device.tablet} {
      width: 50vw;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 12px;
      border-bottom: 1px solid rgba(224, 224, 224, 1);

      :last-child {
        border-right: 0;
        text-align: center;
      }
    }
  }
`

const StatsTable = () => {
  const { topRated } = useRecoilValue(statsState)
  const columns = useMemo(
    () => [
      {
        Header: 'Vitsi',
        accessor: 'data',
      },
      {
        Header: 'Suosio',
        accessor: 'points',
      },
    ],
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    // @ts-ignore
    columns,
    data: topRated,
  })

  // Render the UI for your table
  return (
    <Styles>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </Styles>
  )
}

export default StatsTable
