/* tslint:disable */
/* eslint-disable */
/**
 * Apy Vitsitunkki
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SessionCreateResponse
 */
export interface SessionCreateResponse {
    /**
     * 
     * @type {string}
     * @memberof SessionCreateResponse
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionCreateResponse
     */
    token: string;
    /**
     * 
     * @type {Date}
     * @memberof SessionCreateResponse
     */
    expiresIn?: Date;
}

/**
 * Check if a given object implements the SessionCreateResponse interface.
 */
export function instanceOfSessionCreateResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;

    return isInstance;
}

export function SessionCreateResponseFromJSON(json: any): SessionCreateResponse {
    return SessionCreateResponseFromJSONTyped(json, false);
}

export function SessionCreateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionCreateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'token': json['token'],
        'expiresIn': !exists(json, 'expiresIn') ? undefined : (new Date(json['expiresIn'])),
    };
}

export function SessionCreateResponseToJSON(value?: SessionCreateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'token': value.token,
        'expiresIn': value.expiresIn === undefined ? undefined : (value.expiresIn.toISOString()),
    };
}

