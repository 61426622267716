import React from 'react'
import styled from 'styled-components'
import logo from 'src/assets/logo-2025.png'
import { device } from 'src/styles/variables'
import type { PropsWithChildren } from 'react'

const NavWrapper = styled.nav`
  top: 0;
  right: 2px;
  z-index: 10;
  display: flex;

  @media ${device.tablet} {
    right: 10px;
  }
`

const Img = styled.img`
  height: 50px;

  transition-timing-function: ease;
  transition-duration: 250ms;
  transition-property: transform;

  @media ${device.tablet} {
    height: 60px;
  }

  :hover {
    transform: scale(1.05);
  }
`

const A = styled.a`
  margin: 10px auto 10px 10px;
`

const Nav: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <NavWrapper>
      <A href="https://apy.fi">
        <Img src={logo} />
      </A>
      {children}
    </NavWrapper>
  )
}

export default Nav
