import React, { useState } from 'react'
import { TiInfoLargeOutline } from 'react-icons/ti'
import { FaQuestion } from 'react-icons/fa'
import { FcStatistics } from 'react-icons/fc'
import { RiLogoutCircleLine } from 'react-icons/ri'
import { TiThumbsDown, TiThumbsOk, TiThumbsUp } from 'react-icons/ti'
import styled from 'styled-components'
import CircleButton from 'src/components/CircleButton'
import Modal from 'src/components/Modal'

const H3 = styled.h3`
  margin: 0;
`

const Ul = styled.ul`
  vertical-align: middle;
  padding-left: 20px;
`

const alignMiddle = {
  style: { verticalAlign: 'middle' },
}

const NavInfo = () => {
  const [modalIsOpen, setIsOpen] = useState(false)

  return (
    <>
      <CircleButton onClick={() => setIsOpen(true)} small>
        <TiInfoLargeOutline size={30} />
      </CircleButton>
      <Modal title={'Käyttöohjeet'} open={modalIsOpen} setIsOpen={setIsOpen}>
        <H3>Perusasiat</H3>
        <ol>
          <li>Keskellä vitsit</li>
          <li>Alhaalla kontrollit</li>
          <li>
            Oikealla ylhäällä statistiikkaa <FcStatistics {...alignMiddle} />
            , infoa <TiInfoLargeOutline {...alignMiddle} /> sekä kirjautuminen
            ulos <RiLogoutCircleLine {...alignMiddle} />
          </li>
        </ol>
        <H3>Yleiset kysymykset</H3>
        <ol>
          <li>
            <strong>Mitä alareunan napit tarkoittavat?</strong>
            <Ul>
              <li>
                <TiThumbsUp {...alignMiddle} /> = JATKOON
              </li>
              <li>
                <FaQuestion {...alignMiddle} /> = EN YMMÄRTÄNYT
              </li>
              <li>
                <TiThumbsOk {...alignMiddle} /> = SUPER LIKE
              </li>
              <li>
                <TiThumbsDown {...alignMiddle} /> = EI JATKOON
              </li>
            </Ul>
          </li>
          <li>
            <strong>
              Mitä eroa on <TiThumbsUp {...alignMiddle} /> ja{' '}
              <TiThumbsOk {...alignMiddle} /> napeilla?
            </strong>
            <Ul>
              <li>
                Jyvien erottelu akanoista. Statistiikkaa sivulla{' '}
                <TiThumbsUp {...alignMiddle} /> = 1 piste,{' '}
                <TiThumbsOk {...alignMiddle} /> = 2 pistettä.{' '}
              </li>
            </Ul>
          </li>
          <li>
            <strong>Miten lähetän kommentin?</strong>
            <Ul>
              <li>
                Kirjoita kommentti tekstikenttään ja paina jotakin alareunan
                nappuloista.
              </li>
            </Ul>
          </li>
          <li>
            <strong>Voi ei arvioin vitsin väärin! Mitä voin tehdä?</strong>
            <Ul>
              <li>
                Ominaisuuden hyöty / toteuttamisvaikeus suhde ei ollut tarpeeksi
                suuri joten se jätettiin pois.
              </li>
            </Ul>
          </li>
          <li>
            <strong>Minulla on jokin muu kysymys / löysin bugin</strong>
            <Ul>
              <li>
                Ota yhteyttä Äpyn toimitukseen sähköpostilla toimitus@apy.fi tai
                Telegramilla @apy_bot.
              </li>
            </Ul>
          </li>
        </ol>
      </Modal>
    </>
  )
}

export default NavInfo
