import React, { useEffect, useState } from 'react'
import { animated, to, useSprings } from 'react-spring'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import Card from 'src/components/Card'
import { rateState } from 'src/state/index'
import { secondaryHighlightColor } from 'src/styles/variables'
import { RateValueEnum } from 'src/types'

const pictureWidth = '350px'
const maxContentHeight = '400px'

const Wrapper = styled(animated.div)`
  grid-row-start: 1;
  grid-column-start: 1;
`

const Info = styled.p`
  max-width: 300px;
  margin: 0;
  color: ${secondaryHighlightColor};
  font-weight: 600;
  font-size: 2em;
`

const Inner = styled(animated.div)`
  vertical-align: middle;
  overflow-y: scroll;
  max-width: ${pictureWidth};
  max-height: ${maxContentHeight};
  padding: 20px;
  border: 1px groove #dcdcdc;
  border-radius: 10px;
  background-color: white;
  background-position: center center;
  background-size: ${pictureWidth} ${pictureWidth};
  background-repeat: no-repeat;
  box-shadow: 0 10px 10px -10px rgba(50, 50, 73, 0.4),
    0 10px 10px -10px rgba(50, 50, 73, 0.3);
  text-align: center;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const enter = (i: number) => ({
  x: 0,
  y: i * 0.01,
  scale: 1,
  rot: -10 + Math.random() * 20,
  delay: i * 5,
})
const from = () => ({ x: 0, rot: 0, scale: 1, y: -1000 })
const trans = (r: number, s: number) =>
  `rotateY(${r / 10}deg) rotateZ(${r / 10}deg) scale(${s})`
const N_SHOW_JOKES = 25

const nextNShowJokesIndex = (i: number) =>
  Math.max(Math.ceil(i / N_SHOW_JOKES) * N_SHOW_JOKES, N_SHOW_JOKES)

const Deck: React.FC = () => {
  const [{ allRated, currentId, data, index, rated, rateValue }, setRateState] =
    useRecoilState(rateState)

  const [jokes, setJokes] = useState(
    data.slice(index, nextNShowJokesIndex(index)),
  )
  const [props, set] = useSprings(jokes.length, (i) => ({
    ...enter(i),
    from: from(),
  }))

  useEffect(() => {
    // @ts-ignore
    set((i) => {
      const jokeId = jokes[jokes.length - i - 1].contentId
      const compId = index - 1 >= 0 ? data[index - 1].contentId : -1
      if (jokeId !== compId) return

      let xDir = 0
      let yDir = 0
      if (rateValue === RateValueEnum.JATKOON) {
        xDir = 1
      } else if (rateValue === RateValueEnum.EI_JATKOON) {
        xDir = -1
      } else if (rateValue === RateValueEnum.SUPER_LIKE) {
        yDir = -1
      } else if (rateValue === RateValueEnum.EN_YMMÄRTÄNYT) {
        yDir = 1
      }

      return {
        x: xDir * (window.innerWidth + 300),
        y: yDir * (window.innerWidth + 300),
        config: { tension: 400, friction: 150 },
      }
    })
  })

  useEffect(() => {
    if (rated.length > 0 && rated.length % N_SHOW_JOKES === 0) {
      set((i) => ({
        ...enter(i),
        from: from(),
        config: { tension: 900, friction: 150 },
      }))

      const newJokes = data.slice(index, index + N_SHOW_JOKES)

      setRateState((oldState) => ({
        ...oldState,
        canRate: false,
      }))
      setJokes(newJokes)
      setTimeout(
        () =>
          setRateState((oldState) => ({
            ...oldState,
            canRate: true,
          })),
        700,
      )
    }
  }, [data, index, rated, set, setRateState])

  return allRated || rated.length === data.length ? (
    <Card>
      <Info>Kaikki vitsit arvioitu tältä erää, hyvää työtä!</Info>
    </Card>
  ) : (
    <>
      {props.map(({ x, y, rot, scale }, i) => {
        const { contentId, type, data } = jokes[jokes.length - i - 1]
        let innerStyle = {
          transform: to([rot, scale], trans),
          maxHeight: contentId !== currentId ? '64px' : maxContentHeight,
        }

        if (type === 'PICTURE') {
          innerStyle = {
            ...innerStyle,
            // @ts-ignore
            backgroundImage: `url(${data})`,
            width: pictureWidth,
            height: pictureWidth,
          }
        }

        return (
          <Wrapper
            key={i}
            style={{
              transform: to([x, y], (x, y) => `translate3d(${x}px,${y}px,0)`),
              display: to([x, y], (x, y) =>
                Math.abs(x) > window.innerWidth ||
                Math.abs(y) > window.innerHeight
                  ? 'none'
                  : 'block',
              ),
            }}
          >
            <Inner style={innerStyle}>
              {data && type !== 'PICTURE' && data}
            </Inner>
          </Wrapper>
        )
      })}
    </>
  )
}

export default Deck
