import React from 'react'
import { TiArrowForwardOutline } from 'react-icons/ti'
import { useNavigate } from 'react-router'
import CircleButton from 'src/components/CircleButton'
import Nav from 'src/components/Nav'
import StatsCard from 'src/components/StatsCard'
import StatsTable from 'src/components/StatsTable'
import { Main } from 'src/layout/index'

const Stats: React.FC = () => {
  const navigate = useNavigate()
  return (
    <>
      <Nav>
        <CircleButton onClick={() => navigate('/')} small>
          <TiArrowForwardOutline size={30} />
        </CircleButton>
      </Nav>
      <Main>
        <div>
          <StatsCard />
          <StatsTable />
        </div>
      </Main>
    </>
  )
}

export default Stats
