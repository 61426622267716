import React from 'react'
import { useRecoilValue } from 'recoil'
import { statsState } from 'src/state/index'
import Card from 'src/components/Card'
import styled from 'styled-components'
import { device } from 'src/styles/variables'

const Wrapper = styled.div`
  width: 95vw;
  margin-bottom: 10px;

  @media ${device.tablet} {
    width: 50vw;
  }
`

const InnerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 20px;
  place-items: center;
`

const StatsCard: React.FC = () => {
  const {
    usersCount,
    jokesCount,
    allRatedCount,
    ownRatedCount,
  } = useRecoilValue(statsState)
  return (
    <Wrapper>
      <Card>
        <InnerWrapper>
          <div>
            <strong>Vitsejä:</strong> {jokesCount}
          </div>
          <div>
            <strong>Kaikki arviot:</strong> {allRatedCount}
          </div>
          <div>
            <strong>Käyttäjiä:</strong> {usersCount}
          </div>
          <div>
            <strong>Omat arviot:</strong> {ownRatedCount}
          </div>
        </InnerWrapper>
      </Card>
    </Wrapper>
  )
}

export default StatsCard
