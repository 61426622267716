import {
  Configuration,
  RateContentRequest,
  CreateSessionRequest,
  DefaultApi,
  DeleteSessionRequest,
} from 'src/types'

type apiMethods =
  | 'getContent'
  | 'createSession'
  | 'deleteSession'
  | 'getStatistics'
  | 'rateContent'

type apiParameters = CreateSessionRequest &
  DeleteSessionRequest &
  RateContentRequest

export const callApi = async (
  token: string,
  methodName: apiMethods,
  parameters?: apiParameters,
) => {
  const config = new Configuration({
    basePath: process.env.REACT_APP_API_URL as string,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  const api = new DefaultApi(config)

  const data = api[methodName](parameters!)

  return data
}
