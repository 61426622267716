import styled from 'styled-components'
import { device } from 'src/styles/variables'

interface CircleButtonProps {
  readonly small?: boolean
}

const CircleButton = styled.button<CircleButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.small ? 50 : 70)}px;
  height: ${(props) => (props.small ? 50 : 70)}px;
  margin: 10px;

  padding: 10px;

  border: 0;

  border-radius: 50%;
  background-color: white;

  box-shadow: 0 10px 10px -10px rgba(50, 50, 73, 0.4),
    0 10px 10px -10px rgba(50, 50, 73, 0.3);
  text-align: center;

  cursor: pointer;
  transition-timing-function: ease;
  transition-duration: 250ms;
  transition-property: transform;

  @media ${device.tablet} {
    width: ${(props) => (props.small ? 60 : 80)}px;
    height: ${(props) => (props.small ? 60 : 80)}px;
  }

  :hover {
    transform: scale(1.1);
  }

  :focus {
    outline: none;
  }
`

export default CircleButton
