import styled from 'styled-components'
import { device } from 'src/styles/variables'
import backgroundImage from 'src/assets/kuosi-2025.png'

export const CenteringWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
  min-height: ${() => `${window.innerHeight}px`};
  background-image: url(${backgroundImage});
  background-size: 100%;
  background-position: center;

  @media ${device.laptop} {
    background-size: 40%;
  }
`

export const Main = styled.main`
  display: grid;
  place-items: center;
`
