const colors = [
  '#ef5551',
  '#c8b211',
]

const getRandomElement = (list: string[]) => {
  return list[Math.floor(Math.random() * list.length)]
}

export const highlightColor = getRandomElement(colors)
export const secondaryHighlightColor = getRandomElement(
  colors.filter((c) => c !== highlightColor),
)

export const MOBILE_WIDTH = 320
export const TABLET_WIDTH = 786
export const LAPTOP_WIDTH = 1024

const size = {
  mobile: `${MOBILE_WIDTH}px`,
  tablet: `${TABLET_WIDTH}px`,
  laptop: `${LAPTOP_WIDTH}px`,
}

export const device = {
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
}
