import React from 'react'
import styled from 'styled-components'

import type { PropsWithChildren } from 'react'

interface ButtonProps {
  color?: string
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
}

const StyledButton = styled.button<ButtonProps>`
  display: block;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background: ${({ color, disabled }) =>
    color ? color : disabled ? 'rgba(255, 255, 255, 0.12)' : '#333'};
  color: ${({ disabled }) => (disabled ? 'rgba(255, 255, 255, 0.2)' : 'white')};
  text-transform: uppercase;
  appearance: none;
`

const Button: React.FC<PropsWithChildren<ButtonProps>> = (props) => (
  <StyledButton {...props}>{props.children}</StyledButton>
)

export default Button
