/* tslint:disable */
/* eslint-disable */
/**
 * Apy Vitsitunkki
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ModelError,
  Rate,
  RateJokeRequest,
  SessionCreateRequest,
  SessionCreateResponse,
} from '../models';
import {
    ModelErrorFromJSON,
    ModelErrorToJSON,
    RateFromJSON,
    RateToJSON,
    RateJokeRequestFromJSON,
    RateJokeRequestToJSON,
    SessionCreateRequestFromJSON,
    SessionCreateRequestToJSON,
    SessionCreateResponseFromJSON,
    SessionCreateResponseToJSON,
} from '../models';

export interface CreateSessionRequest {
    sessionCreateRequest: SessionCreateRequest;
}

export interface DeleteSessionRequest {
    authorization: string;
}

export interface RateContentRequest {
    contentId: number;
    rateJokeRequest: RateJokeRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async createSessionRaw(requestParameters: CreateSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SessionCreateResponse>> {
        if (requestParameters.sessionCreateRequest === null || requestParameters.sessionCreateRequest === undefined) {
            throw new runtime.RequiredError('sessionCreateRequest','Required parameter requestParameters.sessionCreateRequest was null or undefined when calling createSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SessionCreateRequestToJSON(requestParameters.sessionCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionCreateResponseFromJSON(jsonValue));
    }

    /**
     */
    async createSession(requestParameters: CreateSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SessionCreateResponse> {
        const response = await this.createSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteSessionRaw(requestParameters: DeleteSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/session`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteSession(requestParameters: DeleteSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSessionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getContentRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<object>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/content`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getContent(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<object>> {
        const response = await this.getContentRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getStatisticsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/statistics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getStatistics(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getStatisticsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async rateContentRaw(requestParameters: RateContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Rate>> {
        if (requestParameters.contentId === null || requestParameters.contentId === undefined) {
            throw new runtime.RequiredError('contentId','Required parameter requestParameters.contentId was null or undefined when calling rateContent.');
        }

        if (requestParameters.rateJokeRequest === null || requestParameters.rateJokeRequest === undefined) {
            throw new runtime.RequiredError('rateJokeRequest','Required parameter requestParameters.rateJokeRequest was null or undefined when calling rateContent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/content/{contentId}/rate`.replace(`{${"contentId"}}`, encodeURIComponent(String(requestParameters.contentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RateJokeRequestToJSON(requestParameters.rateJokeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RateFromJSON(jsonValue));
    }

    /**
     */
    async rateContent(requestParameters: RateContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Rate> {
        const response = await this.rateContentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
