import React from 'react'
import { FallbackProps } from 'react-error-boundary'
import styled from 'styled-components'
import Button from 'src/components/Button'
import Card from 'src/components/Card'
import { CenteringWrapper } from 'src/layout/index'

const Pre = styled.pre`
  white-space: pre-wrap;
  word-break: keep-all;
`

const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  return (
    <CenteringWrapper>
      <Card>
        <p>Jotain meni pieleen:</p>
        <Pre style={{ color: 'red' }}>{error?.message}</Pre>
        <Button onClick={resetErrorBoundary}>Yritä uudestaan</Button>
      </Card>
    </CenteringWrapper>
  )
}

export default ErrorFallback
