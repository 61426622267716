import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { RecoilRoot, useRecoilValue } from 'recoil'
import Card from 'src/components/Card'
import ErrorFallback from 'src/components/ErrorFallback'
import Spinner from 'src/components/Spinner'
import { Wrapper, CenteringWrapper } from 'src/layout/index'
import Login from 'src/pages/Login'
import Rate from 'src/pages/Rate'
import Stats from 'src/pages/Stats'
import { userState } from 'src/state/index'

interface PrivateRouteProps {
  component: React.ComponentType
}
const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
}) => {
  const { isLoggedIn } = useRecoilValue(userState)
  return isLoggedIn ? <Component /> : <Navigate to="/login" replace />
}

function App() {
  return (
    <RecoilRoot>
      <Wrapper>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <React.Suspense
            fallback={
              <CenteringWrapper>
                <Card>
                  <Spinner />
                </Card>
              </CenteringWrapper>
            }
          >
            <BrowserRouter>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                  path="/stats"
                  element={<PrivateRoute component={Stats} />}
                />
                <Route path="/" element={<PrivateRoute component={Rate} />} />
              </Routes>
            </BrowserRouter>
          </React.Suspense>
        </ErrorBoundary>
      </Wrapper>
    </RecoilRoot>
  )
}

export default App
