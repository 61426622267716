import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { device } from 'src/styles/variables'
import styled, { keyframes } from 'styled-components'
import type { PropsWithChildren } from 'react'

const showContainer = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const hideContainer = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
  }
`

const showBackground = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const hideBackground = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

interface StyledModalProps {
  open: boolean
  isClosing: boolean
}

const ModalBackground = styled.div<StyledModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: ${({ open, isClosing }) =>
    isClosing ? 'block' : open ? 'block' : 'none'};
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  animation: ${({ open, isClosing }) =>
      isClosing ? hideBackground : open ? showBackground : hideBackground}
    0.2s ease-out;
`
const ModalContainer = styled.div<StyledModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  display: ${({ open, isClosing }) =>
    isClosing ? 'block' : open ? 'block' : 'none'};
  width: 100%;
  height: 100%;
  animation: ${({ open, isClosing }) =>
      isClosing ? hideContainer : open ? showContainer : hideContainer}
    0.2s cubic-bezier(0.2, 0.87, 0.76, 0.98);
`

const ModalMain = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 95vh;
  margin: 0 auto;
  border-radius: 8px;
  background: white;
  transform: translate(-50%, -50%);

  @media ${device.mobile} {
    width: 95vw;
  }

  @media ${device.tablet} {
    width: 70vw;
  }

  @media ${device.laptop} {
    width: 50vw;
  }
`

const ModalHeader = styled.h2`
  margin: 0;
  padding: 15px 0;
  font-size: 1.8em;
  text-align: center;
`

const ModalBody = styled.div`
  padding: 0 1rem;
  font-weight: 300;
`

const ModalToggle = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 20px;
  height: 20px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 30px;
    height: 3px;
    margin-top: -1.5px;
    margin-left: -15px;
    border-radius: 3px;
    background-color: black;
    transition: all 0.18s ease-out;
    transform: rotate(-45deg);
  }

  &::after {
    transform: rotate(-135deg);
  }

  &:hover {
    &::before,
    &::after {
      transform: rotate(0deg);
    }
  }
`

function useLockBodyScroll() {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow

    // Prevent scrolling on mount
    document.documentElement.style.overflow = 'hidden'

    // Re-enable scrolling when component unmounts
    return () => {
      document.documentElement.style.overflow = originalStyle
    }
  }, [])
}

interface Props {
  title: string
  open: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Modal: React.FC<PropsWithChildren<Props>> = ({ children, title, open, setIsOpen }) => {
  useLockBodyScroll()
  const modalRef = useRef<HTMLDivElement>(null)
  const [isClosing, setIsClosing] = useState(false)

  const hideModal = useCallback(() => {
    setIsClosing(true)
    setTimeout(() => {
      setIsClosing(false)
      setIsOpen(false)
    }, 200)
  }, [setIsOpen, setIsClosing])

  const handleUserKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        open && hideModal()
      }
    },
    [hideModal, open],
  )

  const handleUserMouseClick = useCallback(
    (e: any) => {
      const clientX = e.clientX
      const clientY = e.clientY
      // @ts-ignore
      const bRect = modalRef.current.firstChild.getBoundingClientRect()
      const x = clientX > bRect.right || clientX < bRect.left
      const y = clientY < bRect.top || clientY > bRect.bottom

      if ((x || y) && open) {
        hideModal()
      }
    },
    [hideModal, open],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleUserKeyPress)
    document.addEventListener('mousedown', handleUserMouseClick)

    return () => {
      document.removeEventListener('keydown', handleUserKeyPress)
      document.removeEventListener('mousedown', handleUserMouseClick)
    }
  }, [handleUserKeyPress, handleUserMouseClick])

  return (
    <>
      <ModalContainer ref={modalRef} open={open} isClosing={isClosing}>
        <ModalMain>
          <ModalHeader>
            <ModalToggle onClick={hideModal} />
            {title}
          </ModalHeader>
          <ModalBody>{children}</ModalBody>
        </ModalMain>
      </ModalContainer>
      <ModalBackground open={open} isClosing={isClosing} />
    </>
  )
}

export default Modal
