import React, { useState, useEffect } from 'react'
import { FaQuestion } from 'react-icons/fa'
import { TiThumbsDown, TiThumbsOk, TiThumbsUp } from 'react-icons/ti'
import { useRecoilState, useRecoilValue } from 'recoil'
import styled from 'styled-components'
import Card from 'src/components/Card'
import { apiToken, rateState } from 'src/state/index'
import { TABLET_WIDTH, device } from 'src/styles/variables'
import CircleButton from 'src/components/CircleButton'
import { callApi } from 'src/utils/api'
import { RateValueEnum } from 'src/types'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media ${device.mobile} {
    bottom: 0;
  }

  & > div {
    margin-bottom: 10px;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media ${device.mobile} {
    bottom: 0;
  }

  & > div {
    margin-bottom: 10px;
  }
`

const TextArea = styled.textarea`
  display: block;
  box-sizing: border-box;
  min-width: 300px;
  max-width: 550px;
  max-height: 150px;
  padding: 10px;
  border: 1px solid grey;
  border-radius: 4px;
  resize: none;
`

const Label = styled.label`
  display: block;
  color: black;
  font-weight: 600;
  font-size: 16px;
  line-height: 2;
  text-align: left;
`

const RateControls: React.FC = () => {
  const [{ canRate, currentId, rated }, setRateState] = useRecoilState(
    rateState,
  )
  const token = useRecoilValue(apiToken)
  const [comment, setComment] = useState('')

  useEffect(() => {
    var isAndroid = /(android)/i.test(navigator.userAgent)
    if (isAndroid) {
      const elem = document?.activeElement
      if (elem?.tagName === 'TEXTAREA') {
        window.scrollTo(0, document.body.scrollHeight)
      }
    }
  })

  const rateContent = (rateEnum: RateValueEnum) => {
    if (canRate) {
      const params = {
        contentId: currentId,
        rateJokeRequest: {
          value: rateEnum,
          comment: comment,
        },
      }

      if (!rated.includes(currentId) && rateEnum) {
        setRateState((oldState) => {
          const oldIdx = oldState.data.findIndex(
            (d) => d.contentId === oldState.currentId,
          )

          const { data: oldData } = oldState

          return {
            ...oldState,
            currentId:
              oldIdx + 1 < oldData.length
                ? oldData[oldIdx + 1].contentId
                : oldData[oldData.length - 1].contentId,
            index: oldState.index + 1,
            rateValue: rateEnum,
            rated: [...oldState.rated, currentId],
          }
        })
        setComment('')
        // @ts-ignore
        callApi(token, 'rateContent', params)
      }
    }
  }

  return (
    <Wrapper>
      <Card>
        <Label>Kommentti</Label>
        <TextArea
          onChange={(e) => setComment(e.target.value)}
          value={comment}
        />
      </Card>
      <ButtonsWrapper>
        <CircleButton
          onClick={() => rateContent(RateValueEnum.EI_JATKOON)}
          small
        >
          <TiThumbsDown size={window.innerWidth > TABLET_WIDTH ? 40 : 25} />
        </CircleButton>
        <CircleButton onClick={() => rateContent(RateValueEnum.SUPER_LIKE)}>
          <TiThumbsOk size={window.innerWidth > TABLET_WIDTH ? 50 : 35} />
        </CircleButton>
        <CircleButton onClick={() => rateContent(RateValueEnum.EN_YMMÄRTÄNYT)}>
          <FaQuestion size={window.innerWidth > TABLET_WIDTH ? 50 : 35} />
        </CircleButton>
        <CircleButton onClick={() => rateContent(RateValueEnum.JATKOON)} small>
          <TiThumbsUp size={window.innerWidth > TABLET_WIDTH ? 40 : 25} />
        </CircleButton>
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default RateControls
