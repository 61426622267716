import React from 'react'
import styled from 'styled-components'
import type { PropsWithChildren } from 'react'

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 95vw;
  border-radius: 8px;
  background-color: ${(props) => props.color};
  box-shadow: 0 10px 10px -10px rgba(50, 50, 73, 0.4),
    0 10px 10px -10px rgba(50, 50, 73, 0.3);
`

const Content = styled.div`
  align-self: center;
  padding: 10px;
`

interface CardProps {
  color?: string
}

const Card: React.FC<PropsWithChildren<CardProps>> = ({ children, color = 'white' }) => (
  <Container color={color}>
    <Content>{children}</Content>
  </Container>
)

export default Card
