import React from 'react'
import { FcStatistics } from 'react-icons/fc'
import { useNavigate } from 'react-router'
import CircleButton from 'src/components/CircleButton'
import RateControls from 'src/components/RateControls'
import Deck from 'src/components/Deck'
import Nav from 'src/components/Nav'
import NavInfo from 'src/components/NavInfo'
import NavLogout from 'src/components/NavLogout'
import { Main } from 'src/layout/index'

const Rate: React.FC = () => {
  const navigate = useNavigate()
  return (
    <>
      <Nav>
        <CircleButton onClick={() => navigate('/stats')} small>
          <FcStatistics size={30} />
        </CircleButton>
        <NavInfo />
        <NavLogout />
      </Nav>
      <Main>
        <Deck />
      </Main>
      <RateControls />
    </>
  )
}

export default Rate
