/* tslint:disable */
/* eslint-disable */
/**
 * Apy Vitsitunkki
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Rate
 */
export interface Rate {
    /**
     * 
     * @type {number}
     * @memberof Rate
     */
    contentId: number;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    value: RateValueEnum;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof Rate
     */
    createdAt: Date;
}


/**
 * @export
 */
export const RateValueEnum = {
    JATKOON: 'JATKOON',
    EN_YMMÄRTÄNYT: 'EN_YMMÄRTÄNYT',
    EI_JATKOON: 'EI_JATKOON',
    SUPER_LIKE: 'SUPER_LIKE'
} as const;
export type RateValueEnum = typeof RateValueEnum[keyof typeof RateValueEnum];


/**
 * Check if a given object implements the Rate interface.
 */
export function instanceOfRate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contentId" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function RateFromJSON(json: any): Rate {
    return RateFromJSONTyped(json, false);
}

export function RateFromJSONTyped(json: any, ignoreDiscriminator: boolean): Rate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contentId': json['contentId'],
        'userId': json['userId'],
        'value': json['value'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function RateToJSON(value?: Rate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contentId': value.contentId,
        'userId': value.userId,
        'value': value.value,
        'comment': value.comment,
        'createdAt': (value.createdAt.toISOString()),
    };
}

